import styled from "styled-components";
import { CgCodeClimate } from "react-icons/cg";

import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
export const Nav = styled.nav`
  background: ${({ scrolled, navBarColor }) =>
    navBarColor ? navBarColor : scrolled ? "#181818" : "transparent"};

  transition: 0.8s all ease;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;

  @media screen and (max-width: 960px) {
    background: ${({ click, scrolled, navBarColor }) =>
      navBarColor
        ? navBarColor
        : click
        ? "#121212"
        : scrolled
        ? "#121212"
        : "transparent"};
    transition: none;
    //transition: 0.2s all ease;
  }
`;
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 1000px;
`;

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  color: "#141414";

  :hover {
    text-decoration: none;
  }
`;

export const NavIcon = styled(CgCodeClimate)`
  margin: 0 0.5rem 0 2rem;
`;
export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin: 0;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: ${({ click }) => (click ? "100%" : "-1000px")};
    opacity: 1;
    transition: all 0.2 ease;
    background: #141414;
  }
`;
export const NavItem = styled.li`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: "#141414";

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const AnchorLinkComp = styled(AnchorLink)`
  color: #fff;
  display: flex;
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: "Roboto", sans-serif;

  &:hover {
    color: #007bff;
    text-decoration: none;
    transition: all 0.3 ease;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    text-decoration: none;
    &:hover {
      color: #007bff;
      text-decoration: none;
      transition: all 0.3 ease;
    }
  }
`;
