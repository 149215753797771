import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      indexImage: file(relativePath: { eq: "images/backgroundImage.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    /*    <HeroContainer>
      <HeroBg>
   
      </HeroBg>
      <HeroContent>
        <HeroItems>
          <HeroH1>IT-Solutions &amp; Consulting</HeroH1>
          <HeroP>by Johannes Lüftenegger</HeroP>
        </HeroItems>
      </HeroContent>
    </HeroContainer> */
    <BlogHeroContainer>
      <BlogHeroImage>
        <BlogHeaderImage
          key="2"
          image={data.indexImage.childImageSharp.gatsbyImageData}
          alt=""
        />
      </BlogHeroImage>
      <BlogHeroContent>
        <BlogHeroItems>
          <BlogHeroH1>IT-Solutions &amp; Consulting</BlogHeroH1>
          <BlogHeroP>by Johannes Lüftenegger</BlogHeroP>
        </BlogHeroItems>
      </BlogHeroContent>
    </BlogHeroContainer>
  );
};

export default Hero;

const BlogHeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 100vh;
`;

const BlogHeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 1rem;
  position: relative;
  margin-top: -80px;
  color: #fff;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  }
`;

const BlogHeroImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const BlogHeroContent = styled.div`
  z-index: 3;
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px) / 2);
`;
const BlogHeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  color: #fff;
  line-height: 1.1;
  font-weight: bold;
`;
const BlogHeroH1 = styled.div`
  font-size: clamp(3rem, 6vw, 5rem);
  margin-bottom: 1.5rem;
  // letter-spacing: 3px;
  padding: 0 1rem;
`;
const BlogHeroP = styled.div`
  font-size: clamp(1rem, 3vw, 3rem);
  margin-bottom: 2rem;
`;
