import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Row, Col } from "react-bootstrap";

import { faBorderNone } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = getImage(node.data.target);
      return (
        <Col xs={12} md={6} key={node.data.target.id}>
          <GatsbyImage image={image} alt="" />
        </Col>
      );
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return (
        <Col xs={12} md={6} key={node.id}>
          <ListContainer>{children}</ListContainer>
        </Col>
      );
    },
  },
};

const Consulting = ({ richTextJson }) => {
  return <Row className="mt-1 ">{renderRichText(richTextJson, options)}</Row>;
};

export default Consulting;

const ListContainer = styled.div`
  li {
    list-style-type: none;
  }
`;
