import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Col } from "react-bootstrap";
/* import { list_style } from "../components/portfolio.module.scss"; */

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = getImage(node.data.target);
      return <GatsbyImage image={image} alt="" />;
    },
    [BLOCKS.UL_LIST]: (node, children) => {
      return <p key={node.id}>{children}</p>;
    },
  },
};

const Portfolio = ({ richTextJson }) => {
  return (
    <Col lg={4} className="mt-1 ">
      {renderRichText && renderRichText(richTextJson, options)}
    </Col>
  );
};

export default Portfolio;
