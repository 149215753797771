import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Row, Col } from "react-bootstrap";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = getImage(node.data.target);

      return (
        <Col xs={12} md={6} className="order-2" key={node.data.target.id}>
          <GatsbyImage image={image} className="order-2" alt="" />
        </Col>
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <Col xs={12} md={6} className="order-1" key={node.id}>
          <p>{children}</p>
        </Col>
      );
    },
  },
};

const Solutions = ({ richTextJson }) => {
  return (
    <Row className="section mt-1">
      {renderRichText && renderRichText(richTextJson, options)}
    </Row>
  );
};

export default Solutions;
