import React, { useState, useEffect } from "react";

import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
/* */
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavItem,
  AnchorLinkComp,
} from "./NavbarElements";

const Navbar = ({ page, navBarColor }) => {
  const [click, setClick] = useState(false);
  const [scroll, setScroll] = useState(false);
  const handleClick = () => setClick(!click);

  const onScroll = () => {
    if (window.scrollY >= 80) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    onScroll();
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "#FFF" }}>
        <Nav
          scrolled={scroll}
          click={click}
          page={page}
          navBarColor={navBarColor}
        >
          <NavbarContainer>
            <NavLogo to="/#home">
              <NavIcon />
              JL
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <AnchorLinkComp to="/#section1" title="Solutions" />
              </NavItem>
              <NavItem>
                <AnchorLinkComp to="/#section2" title="Consulting" />
              </NavItem>
              <NavItem>
                <AnchorLinkComp to="/#section3" title="Portfolio" />
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};
export default Navbar;
