import React, { useState } from "react";

import { sidebarContact, toggle, password } from "./sidebarContact.module.scss";
import { Button } from "react-bootstrap";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const SidebarContact = () => {
  const [isActive, setActive] = useState(false);

  function openSidebarContact() {
    setActive(!isActive);
  }
  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    message: "",
    token: "",
  });

  const [submissionState, setSubmissionState] = useState({
    submitted: false,
    success: false,
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const url = "/api/mailer.php";
    if (!emailData.email) {
      return;
    }
    const requestMetadata = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: JSON.stringify(emailData),
    };

    fetch(url, requestMetadata)
      .then((response) => {
        setSubmissionState({
          submitted: true,
          success: response.status === 200,
        });
      })
      .catch(function (response) {
        setSubmissionState({ submitted: true, success: false });
        console.log(response);
      });
  };

  return (
    <div>
      <div className={`${sidebarContact}`} data-active={isActive}>
        <Button
          variant="outline-primary"
          className={toggle}
          onClick={openSidebarContact}
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </Button>
        <h2>Kontakt</h2>
        <form action="">
          <input
            type="text"
            name="name"
            placeholder="Name"
            onChange={(e) => {
              const val = e.target.value;
              setEmailData((obj) => {
                return { ...obj, name: val };
              });
            }}
          ></input>
          <input
            type="text"
            name="email"
            placeholder="EMail"
            onChange={(e) => {
              const val = e.target.value;
              setEmailData((obj) => {
                return { ...obj, email: val };
              });
            }}
          ></input>
          <input
            type="text"
            name="a_password"
            className={password}
            tabIndex="-1"
            autoComplete="off"
            onChange={(e) => {
              const val = e.target.value;
              setEmailData((obj) => {
                return { ...obj, token: val };
              });
            }}
          ></input>
          <textarea
            type="rel"
            name="message"
            placeholder="Nachricht"
            onChange={(e) => {
              const val = e.target.value;
              setEmailData((obj) => {
                return { ...obj, message: val };
              });
            }}
          />

          <button
            type="submit"
            name="send"
            onClick={handleFormSubmit}
            style={{
              display:
                submissionState.submitted && submissionState.success
                  ? "none"
                  : "block",
            }}
          >
            Senden
          </button>
          <SuccessMessageBox
            state={submissionState.success}
            style={{
              display: submissionState.submitted ? "block" : "none",
            }}
          >
            {submissionState.success
              ? "Ihre Nachricht wurde gesendet!"
              : "Es ist ein technischer Fehler aufgetreten!"}
          </SuccessMessageBox>
          <div id="inline-badge"></div>
        </form>
      </div>
    </div>
  );
};

const SuccessMessageBox = styled.div`
  padding: 0.5rem 2rem;
  margin: 0.5rem 0rem;
  width: 100%;
  background-color: ${({ state }) =>
    state ? "rgba(37, 187, 51, 0.5)" : "rgba(187, 33, 36, 0.5)"};
  border-radius: 50px;
`;

export default SidebarContact;
