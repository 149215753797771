import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { navigate } from "gatsby";

import Layout from "../components/layout";
import Head from "../components/head";

import Hero from "../components/Hero";

import Navbar from "../components/Navbar";

import { Container, Row } from "react-bootstrap";

import Consulting from "../components/consulting";
import Solutions from "../components/solutions";
import Service from "../components/service";
import Portfolio from "../components/portfolio";
import SidebarContact from "../components/sidebarContact";

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en";
  }

  const lang =
    navigator && navigator.language && navigator.language.split("-")[0];
  console.log(lang);
  return lang;
};

const IndexPage = (props) => {
  useEffect(() => {
    const urlLang = getRedirectLanguage();

    console.log("useEffect");
    if (urlLang === "en") navigate(`/${urlLang}`);
  }, []);

  return (
    <div>
      <Navbar />
      <span id="home" />

      <Hero />
      <SidebarContact />
      <Layout>
        <Head title="Home" />
        <Container>
          <Row>
            {props.data.allContentfulServiceCard.edges.map((edge) => {
              return (
                <Service
                  richTextJson={edge.node.serviceCardContent}
                  key={edge.node.contentful_id}
                />
              );
            })}
          </Row>

          <h2 id="section1" className="mt-5">
            Solutions
          </h2>
          {props.data.allContentfulSolutionsCard.edges.map((edge) => {
            return (
              <Solutions
                richTextJson={edge.node.solutionsCardContent}
                key={edge.node.contentful_id}
              />
            );
          })}
          <h2 id="section2" className="mt-5">
            Consulting
          </h2>
          {props.data.allContentfulConsultingCard.edges.map((edge) => {
            return (
              <Consulting
                richTextJson={edge.node.consultingCardContent}
                key={edge.node.contentful_id}
              />
            );
          })}
          <h2 id="section3" className="mt-5">
            Portfolio
          </h2>
          <Row>
            {props.data.allContentfulPortfolioCard.edges.map((edge) => {
              return (
                <Portfolio
                  richTextJson={edge.node.portfolioCardContent}
                  key={edge.node.contentful_id}
                />
              );
            })}
          </Row>
        </Container>
      </Layout>
    </div>
  );
};

export default IndexPage;

export const data = graphql`
  query($locale: String) {
    allContentfulServiceCard(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: serviceCardId, order: ASC }
    ) {
      edges {
        node {
          contentful_id
          serviceCardContent {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    allContentfulSolutionsCard(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: solutionsCardId, order: ASC }
    ) {
      edges {
        node {
          contentful_id
          solutionsCardContent {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    allContentfulConsultingCard(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: consultingCardId, order: ASC }
    ) {
      edges {
        node {
          contentful_id
          consultingCardContent {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    allContentfulPortfolioCard(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: portfolioCardId, order: ASC }
    ) {
      edges {
        node {
          contentful_id
          portfolioCardContent {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
