import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { serviceImage, serviceSection } from "./service.module.scss";
import { Col } from "react-bootstrap";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = getImage(node.data.target);

      return (
        /*         <Col xs={12} md={6} className="order-2 " key={node.data.target.id}>
          <div> */

        <GatsbyImage image={image} className={serviceImage} alt="" />
        /*           </div>
        </Col> */
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p>{children}</p>;
    },
  },
};

const Service = ({ richTextJson }) => {
  return (
    <Col lg={4} className={`mt-1 ${serviceSection}`}>
      {renderRichText && renderRichText(richTextJson, options)}
    </Col>
  );
};

export default Service;
